import React, {Fragment} from 'react';

const About = () => {
  return (
    <Fragment>
      <h1>
        About this App
      </h1>
      <p>App to search github users</p>
      <p>version 1.0.0</p>
    </Fragment>
  )
};

export default About;